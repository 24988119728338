<template>
    <div id="contact">
        <div class="container">
            <hr />
        </div>
        <div class="container col-xl-10 col-xxl-8 py-5">
            <div class="row align-items-center g-lg-5 py-5">
                <div class="col-lg-5 text-center text-lg-start">
                    <h1 class="display-4 fw-bold lh-1 mb-3">{{ $t("buttons.get_in_touch") }}</h1>
                    <p class="col-lg-10 fs-4"><span class="text-info me-4">E:</span> info@exceltour.co.uk</p>
                    <p class="col-lg-10 fs-4"><span class="text-info me-4">T:</span> <span class="text-secondary">+44</span> 0330 223 7173</p>
                </div>
                <div class="col-md-10 mx-auto col-lg-7">
                    <form class="p-3 p-md-5 border rounded-3 bg-white"
                        name="exceltour_contact"
                        @submit.prevent="handleSubmit"
                        id="exceltour_contact"
                        data-netlify="true"
                        netlify
                        data-netlify-honeypot="bot-field"
                        :readonly="pending"
                        v-if="!submitted">

                    <input type="hidden" name="form-name" value="exceltour_contact" />
                        
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="floatingInput" name="name" placeholder="Name" v-model="form.name" required>
                        <label for="floatingInput">{{ $t("form.name") }}</label>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="floatingInput" name="company" placeholder="Organisation" v-model="form.company">
                        <label for="floatingInput">{{ $t("form.company") }}</label>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="floatingInput" name="email" placeholder="name@example.com" v-model="form.email" required>
                        <label for="floatingInput">{{ $t("form.email") }}</label>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="text" class="form-control" id="floatingInput" name="phone" placeholder="+44 0000000000" v-model="form.phone">
                        <label for="floatingInput">{{ $t("form.phone") }}</label>
                    </div>
                    <div class="form-floating mb-3">
                        
                        <select class="form-select" :aria-label="$t('form.language')" id="floatingInput" v-model="form.language">
                            <option disabled selected>{{$t("form.language")}}</option>
                            <option value="en">English</option>
                            <option value="it">Italiano</option>
                        </select>
                        <label for="floatingInput">{{ $t("form.language") }}</label>
                    </div>
                    <div class="form-floating mb-3">
                        <textarea class="form-control" style="height:6rem;" id="floatingInput" name="message" rows="4" placeholder="Your message" v-model="form.message" required></textarea>
                        <label for="floatingInput">{{ $t("form.message") }}</label>
                    </div>
                    <button class="w-100 btn btn-lg btn-primary" type="submit" :disabled="pending || submitted">
                        <span v-if="pending">{{ $t("form.sending") }}</span>
                        <span v-else>{{ $t("form.send") }}</span>
                    </button>


                    <div class="alert alert-danger" v-if="error" role="alert">
                        {{ $t("form.error") }}
                    </div>
            
        
                    </form>
                    <div class="alert alert-primary" v-else role="alert">
                      <h4 class="alert-heading">{{ $t("form.sent") }}</h4>
                        <p class="mb-0">{{ $t("form.sent_body") }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import axios from "axios"

    /* eslint-disable */
    export default{    
        name: 'Home',
        data(){
            return {
                submitted: false,
                pending: false,
                error: false,
                form: {
                    name: "",
                    company: "",
                    email: "",
                    phone: "",
                    language: "",
                    message: ""
                }
            }
        },
        created(){
            this.form.language = this.$i18n.locale
        },
        methods: {
            encode (data) {
            return Object.keys(data)
                .map(
                key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
                )
                .join("&");
            },
            handleSubmit () {
            this.pending = true
            const axiosConfig = {
                header: { "Content-Type": "application/x-www-form-urlencoded" }
            };
            axios.post(
                "/",
                this.encode({
                "form-name": "exceltour_contact",
                ...this.form
                }),
                axiosConfig
            ).then(() => {
                this.submitted = true
                this.$router.push('/')
            }).catch(() => {
                this.error = true
            }).finally(() => {
                this.pending = false
            });
            }
        }
    }
    </script>