<template>
  <div>
    <main-navbar />
    <hero />
    <home />
    <cards />
    <contact />
    <main-footer />
  </div>
</template>

<script>
import MainNavbar from './components/MainNavbar'
import Hero from './components/Hero'
import Home from './components/Home'
import Cards from './components/Cards'
import Contact from './components/Contact'
import MainFooter from './components/Footer'

export default {
  name: 'App',
  components: {
    MainNavbar,
    Hero,
    Home,
    Cards,
    Contact,
    MainFooter
  }
}
</script>