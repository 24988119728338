<template>
    <div class="bg-light">
        <div class="container col-xxl-8 px-4 py-5" id="about">
        <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
            <div class="col-10 col-sm-8 col-lg-6">
            <img src="@/assets/img/pics/group.jpg" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy">
            </div>
            <div class="col-lg-6">
            <h1 class="display-5 fw-bold lh-1 mb-3">{{ $t("content.main_header") }}</h1>
            <p class="lead">
                {{ $t("content.main_body") }}
            </p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                <a class="btn btn-primary btn-lg px-4" href="#contact">{{ $t("buttons.get_in_touch") }}</a>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script type="text/javascript">
/* eslint-disable */
export default{    
    name: 'Home'
}
</script>