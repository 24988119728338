<template>
    <nav class="navbar navbar-dark navbar-expand-lg bg-primary">
        <div class="container">
            <a class="navbar-brand my-4" href="#">
                <img src="@/assets/img/logo-invert.svg" alt="Exceltour logo" height="80">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ms-auto">
                <li class="nav-item me-2">
                <a class="nav-link btn btn-outline-light" href="#about">{{ $t("buttons.our_work") }}</a>
                </li>
                <li class="nav-item me-2">
                <a class="nav-link btn btn-outline-light" href="#contact">{{ $t("buttons.contact") }}</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link btn btn-outline-light dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img v-if="$i18n.locale == 'it'" src="@/assets/img/flag_it.svg" style="width: 1.5rem;" />
                        <img v-else src="@/assets/img/flag_gb.svg" style="width: 1.5rem;"  />
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li><a class="dropdown-item" @click.prevent="$i18n.locale = 'en'"><img src="@/assets/img/flag_gb.svg" style="height: 1rem;" class="mr-2" /> English</a></li>
                        <li><a class="dropdown-item" @click.prevent="$i18n.locale = 'it'"><img src="@/assets/img/flag_it.svg" style="height: 1rem;" class="mr-2" /> Italiano</a></li>
                        
                    </ul>
                </li>
                <!--li class="nav-item">
                    
                    <select class="form-select" v-model="$i18n.locale">
                        <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
                    </select>
                </li-->
            </ul>
            </div>
            <!--div class="col-md-3 text-end">
                <a class="btn btn-lg btn-outline-light me-3" href="#about">{{ $t("buttons.our_work") }}</a>
                <a class="btn btn-lg btn-light" href="#contact">{{ $t("buttons.contact") }}</a>
            
            
              <select class="form-select" v-model="$i18n.locale">
                <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
              </select>
            
            </div-->
        </div>
    </nav>
</template>
<script type="text/javascript">
export default{
    name: 'MainNavbar',
    computed:{
        localeFlag(){
            switch(this.$i18n.locale){
                case 'it': return "@/assets/img/flag_it.svg";
                case 'en': return "@/assets/img/flag_gb.svg";
                default: return "@/assets/img/flag_gb.svg"
            }
        }
    }
}
</script>