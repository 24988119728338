import { createApp } from 'vue'
import App from './App.vue'
import { createI18n } from 'vue-i18n'
import messages from './locales/messages.js'
import "@/assets/sass/main.scss"
import "bootstrap/dist/js/bootstrap.bundle.min.js"

const app = createApp(App)
const i18n = createI18n({
    locale: navigator.language || 'en',//'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages,
    // something vue-i18n options here ...
  })
app.use(i18n)
app.mount('#app')


