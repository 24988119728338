<template>
    <div class="hero">
        <div class="cover"></div>
        <div class="container">
            <div class="col-lg-5 ms-auto pe-5">
                <img src="@/assets/img/andiamo.svg" class="d-block" alt="Andiamo!"/>
                <p class="lead mb-4 mt-4 text-light text-end">
                    {{ $t("content.hero_tagline") }}
                </p>
            </div>
        </div>
  </div>
</template>
<script type="text/javascript">
/* eslint-disable */
export default{    
    name: 'Hero'
}
</script>